import * as Wildfire from "../../layers/static/Wildfire";

import base, { Config } from "../../config";
import { camerasAZGF, camerasPima, camerasSantaCruz } from "../../layers/misc/cameras";

import { LatLng } from "leaflet";

const config: Config = {
  ...base,
  ...{
    title: "Arizona Game and Fish Department Water",
    center: new LatLng(33.25, -110.983392),
    zoom: 7,
    basemap: "Topographic",
    agencyPassList: [
      "United States Geological Survey",
      "Santa Cruz County Flood Control District",
      "Arizona Game and Fish Department",
      "Arizona Department of water Resources",
      "Navajo County Flood Control District",
      "Pima County Regional Flood Control District",
    ],
    layers: {
      static: [Wildfire.last(3)],
      dynamic: [],
      misc: [
        {
          title: "Webcams",
          layers: [
            { name: "Pima County", cameras: camerasPima },
            { name: "Arizona Game and Fish Department", cameras: camerasAZGF },
            { name: "Santa Cruz County", cameras: camerasSantaCruz },
          ],
        },
      ],
      lightning: false,
      cbrfc: false,
    },
  },
};

export default config;
